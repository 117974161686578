@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.main.active {
    @apply md:w-full md:ml-0;
}

.sidebar-menu.toggle-icons {
    width: 8%;
}

/* Plantilla */
.notification-tab {
    .active {
        @apply text-blue-500 border-blue-500;
    }
}

.order-tab {
    .active {
        @apply bg-blue-500 text-white hover:text-white;
    }
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 290px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 12px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

tr:nth-child(even) {
    @apply bg-gray-50;
}

.plugin-clear_button .clear-button {
    font-size: 20px;
}
